@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
* {
  font-family: 'Open Sans', sans-serif;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0;
}

.todo-body{
   background-image: url('../public/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.btn {
  background-color: #3b7dc9;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "RM Neue",sans-serif;
  line-height: 1.15;
  font-size: large;
  margin-right:3px;
  padding: 12px 18px;
  text-align: center;
  transition: color .13s ease-in-out,background .13s ease-in-out,opacity .13s ease-in-out,box-shadow .13s ease-in-out;
}

.btn:active {
  background-color: #006AE8;
}

.btn:hover {
  background-color: #539bf3;
}

.todo-edit{
  background-color: transparent;
  padding:6px 9px;
}

.todo-cancel{
  background-color: transparent;
  padding:6px 9px;
}

.todo-edit:hover{
  background-color: gainsboro;
  border-radius: 5px;
}

.todo-cancel:hover{
  background-color: gainsboro;
  border-radius: 5px;
}

.todo-cancel:hover .fa-trash{ 
  color: #e80000;
}

.todo-edit:hover .fa-edit{ 
  color: #f7bc4f;
}

.edit-confirm{
  padding:6px 9px;
  background-color: transparent;
}

.edit-cancel{
  padding:6px 9px;
  background-color: transparent;
}

.fa-check{
  color:#006AE8
}

.fa-times{
  color:#006AE8
}

.fa-edit{
  color: #006AE8;
}

.fa-trash{
  color: #006AE8;
}

.todoapp-main{
padding-top: 15px;
align-items: center;
justify-content: center;
width: 55vw;
min-height: 80vh;
margin: 30px auto 30px auto;
border-radius: 8px;
box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
background-color: rgba(255,255,255,0.95);
}

input[type=checkbox] {
  transform:scale(1.5);
  cursor: pointer;
  margin-bottom: auto;
  margin-right: 10px;
  margin-top: 10px;
}

.btn-group-edit{
  display: flex;
  flex-direction: column;
  transform: translateY(10px);
  margin-right: 13px;
}

.btn-group{
  display: flex;
  flex-direction: column;
  transform: translateY(10px);
  margin-top: auto;
}

.cb-wrapper{
  display: flex;
  padding:10px 10px 0px 10px;
}

.form-view-main{
  display: grid;
  grid-template-columns: 90% 10%;
  margin: 0px 10px;
  border-bottom: gainsboro 2px solid;
  padding-bottom: 10px;
}

.form-edit-main{
  padding-bottom: 10px;
  border-bottom: gainsboro 2px solid;
  margin: 0px 10px;
}

.form-edit{
  display: grid;
  grid-template-columns: 90% 10%;
  border: 0;
  resize: none;
}

.form-edit input{
  resize: none;
  outline: none;
  padding: 10px 10px 0px 30px;
  background: rgba(255, 255, 255, 0.1);
}

.edit-confirm:hover .fa-check{ 
  color: #04f82d;
}

.edit-cancel:hover .fa-times{ 
  color: #f80404;
}

.edit-confirm:hover{
  background-color: gainsboro;
  border-radius: 5px;
}

.edit-cancel:hover{
  background-color: gainsboro;
  border-radius: 5px;
}

.todo-label{
  height: 100%;
  font-size: 22px;
  margin-bottom: auto;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-hyphens:auto;
  hyphens: auto;
  border: 0;
}

.list-heading{
  margin-top: 20px;
  font-size: small;
  letter-spacing: 1px;
  text-align: center;
}

li{
  list-style-type:none;
}

.navbar-todo{
  padding: 30px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  width:100vw;
}

.navbar-todo a{
  font-family: 'Oswald', sans-serif;
  font-weight:600;
  display: inline;
  text-transform:uppercase;
  text-decoration:none;
  color:#006AE8;
  margin:0 12px;
  font-size:20px;
  letter-spacing:1px;
  padding-right: 30px;
}

.navbar-todo a:hover{
    transition: all 0.2s ease;
    color: #53e8f3;
    font-weight: 600;  
    font-size: larger;
}

.header{
  background-image: linear-gradient(to left,#73c9b6,#606ba7);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 30px; 
  letter-spacing: -1px; 
}
.header img {
  height: 40px;
  padding-right: 10px;
  color: #006AE8;
  filter:saturate(3);
}

.filters{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.form-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-input{
  padding-left: 10px;
  width: 40vw;
  height: 40px;
  margin-right: 3px;
  background-color: #f7f7f7;
  border: 2px solid #3b7dc9;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  font-size: large;
  resize: none;
}

.toggle-btn:first-of-type{
  border-radius: 8px 0px 0px 8px;
  }
  
.toggle-btn:last-of-type{
  border-radius: 0px 8px 8px 0px;
  }
  
.btn-add{
  border-radius: 0px 8px 8px 0px;
  }

/* HOME ------------------------------------------------------------------------------ */

.home-body{
  background-image: url("https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width:100%;
  overflow-x: hidden;
}

.navbar-home{
  padding: 30px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  width:100vw;
}

.navbar-home a{
  font-family: 'Oswald', sans-serif;
  font-weight:600;
  display: inline;
  text-transform:uppercase;
  text-decoration:none;
  color:#d0f8f2;
  margin:0 12px;
  font-size:20px;
  letter-spacing:1px;
  padding-right: 30px;
}

.navbar-home a:hover{
    transition: all 0.2s ease;
    color: #549aaa;
    font-weight: 600;  
    font-size: larger;
}

.home-heading{
  position:fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: fade 1.5s;
}

@keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}

.home-heading h1{
  color: #bce7e1;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size:calc(32px + 2vw);
  letter-spacing: -1px; 
  text-align: center; 
  -webkit-text-stroke:0.02em #111;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
}

.home-heading h2{
  color: #addfd7;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size:calc(20px + 1.8vw);
  font-weight: light; 
  text-align: center; 
  -webkit-text-stroke:0.02em #111;
  -webkit-user-select: none;
  user-select: none;
  margin:0;
}

.home-section{
  position:absolute;
  top: 100vh;
  width: 100vw;
  display: grid;
  overflow: hidden; 
}

.section-1{
  background-color: #b8d7df;
  padding: 50px 20px;
}

.home-section li{
  margin-bottom: 10px;
}

.sec1-img{
  width: 50%;
  float:left;
  margin: auto;
}

.sec1-img img{
  width: 500px;
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
  text-align: center;
  display: block;
  margin: auto;
}

.sec1-text{
  width: 50%;
  float: left;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 2.4vw; 
}

.btn-sec1{
  margin-top: 10px;
  font-size: 1vw;
  padding:10px;
}

.seperator{
  height: 20vh;
  background-color: transparent;
}

.section-2{
  background-color: #a7bcb9;
  padding:50px 20px;
}

.btn-sec2{
  background-color: #53746f;
  margin-top: 10px;
  font-size: 1vw;
  padding:10px;
}

.btn-sec2:hover{
  background-color: #7e9b96;
}

.sec2-img{
  width: 50%;
  float: right;
  margin: 10px auto;
}

.sec2-img img{
  width: 500px;
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto;
}

.sec2-text{
  width: 50%;
  float: left;
  text-align: right;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 2.4vw; 
}

.section-3{
  background-color: #e0ebeb; 
  padding:50px 20px;
}

.btn-sec3{
  background-color: #6d6d6d; 
  margin-top: 10px;
  font-size: 1vw;
  padding:10px;
}

.btn-sec3:hover{
  background-color: #c6caca; 
}

.sec3-img{
  width: 50%;
  float:left;
  margin: 10px auto;
}

.sec3-img img{
  width: 500px;
  max-width: 90%;
  max-height: 90%;
  border-radius: 5px;
  text-align: center;
  display: block;
  margin: auto;
}

.sec3-text{
  width: 50%;
  float: left;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 2.4vw; 
}

.footer{
  max-height:16vh;
  background-color: #1e1f1f;
  font-size: 1.2vw; 
  color:white;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  padding:0px 2vw;
  margin-top: auto;
}

.footer-left{
  margin: auto 0px;
}

.footer-middle{
  margin: auto;
}

.footer-right{
  margin: auto 0px;
  text-align: right;
}

.scroll-btn {
  border: none;
  outline: none; 
  background-color: rgb(105, 104, 104); 
  color: white; 
  cursor: pointer; 
  padding: 12px; 
  border-radius: 10px; 
  font-size: 1.2vw; 
}

.scroll-btn:hover {
  background-color: rgb(76, 78, 78); 
}

/* ABOUT US ------------------------------------*/

.about-body{
  background-color: rgb(243, 243, 243);
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  max-width:100%;
}

.navbar-about{
  padding: 30px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  width:100vw;
}

.navbar-about a{
  font-family: 'Oswald', sans-serif;
  font-weight:600;
  display: inline;
  text-transform:uppercase;
  text-decoration:none;
  color:rgb(0, 0, 0); 
  margin:0 12px;
  font-size:20px;
  letter-spacing:1px;
  padding-right: 30px;
}

.navbar-about a:hover{
    transition: all 0.2s ease;
    color: #005eeb34;
    font-weight: 600;  
    font-size: larger;
}

.about-wrapper{
  display: grid;
  grid-template-columns: 50% 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.about-text{
  line-height: 120%;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 2.4vw; 
  border-left: 3px solid black;
  padding-left: 2vw;
  margin:auto;
}

.about-text h1{
  color:rgb(0, 0, 0);
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 2.2vw;
}

.about-text p{
  color:rgb(0, 0, 0);
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 1.2vw;
  word-wrap: break-word;
}

.about-img{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-img img{
  max-width: 40%;
  max-height: 40%;
}

.about-footer{
  max-height:16vh;
  background-color: #1e1f1f;
  font-size: 1.2vw;
  color:white;
  display: flex;
  position:absolute;
  bottom:0;
  box-sizing: border-box;
  padding: 0px 2vw;
  width: 100vw;
}

.about-footer-left{
  width: 50%;
  margin: auto 0px;
}

.about-footer-right{
  width: 50%;
  float: right;
  text-align: right;
  margin: auto;
}

/* CALENDAR ----------------------------------------------- */
.calendar-body{
  background-image: url('../public/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.calendar-container{
  width :-moz-fit-content;
  width: fit-content;
  max-width: 100%;
  display: flex;
  margin: 8vh auto 1vh auto;
}

.calendar-selected-text{
  color:rgb(0, 0, 0);
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 25px;
  text-align: center;
  margin: 5px;
}

/* MEDIA QUERIES ---------------------------------------------- */

@media screen and (max-width:1360px){
  .todoapp-main{
    width:65vw;
  }
}

@media screen and (max-width:760px){
  .navbar-todo a{
    display:block;
    margin-bottom: 5px;
    text-align: center;
    padding:0;
  }

  .navbar-home a{
    display:block;
    margin-bottom: 5px;
    text-align: center;
    padding:0;
  }

  .navbar-about a{
    display:block;
    margin-bottom: 5px;
    text-align: center;
    padding:0;
  }

  .navbar-about a{
    display:block;
    margin-bottom: 5px;
    text-align: center;
    padding:0;
  }

  .form-edit{
    grid-template-columns: 85% 15%;
  }
  .form-view-main{
    grid-template-columns: 85% 15%;
  }
  .footer{
    max-height: 12vh;
    font-size:calc(14px + 0.8vw);
    font-size: 1.6vw;
  }
  .about-footer{
    font-size:calc(14px + 0.8vw);
    font-size: 1.6vw;
  }
  .about-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  }
  .about-text{
    border:none;
    border-top: 3px solid black;
    margin-top: 10px;
    font-size:calc(20px + 0.8vw);
  }
  .about-text h1{
    text-align: center;
    font-size:calc(20px + 0.8vw);
  }
  .about-img img{
    width: 30%;
    height: 30%;
    margin-bottom: 10px;
  }
  .btn-sec1{
    font-size: 2vw;
  }
  .btn-sec2{
    font-size: 2vw;
  }
  .btn-sec3{
    font-size: 2vw;
  }
  .todoapp-main{
    width: 75vw;
  }
}

 @media screen and (min-width:1360px){
  .btn-group{
    flex-direction: row;
  }
  .todo-cancel{
    margin:0
  }
  .todo-edit{
    margin:auto 0 0 0;
  }
  .btn-group-edit{
    flex-direction: row;
    width:-moz-fit-content;
    width: fit-content;
  }
}


@media screen and (max-width:500px){
.todoapp-main{
  width: 80vw;
  margin-top: 0;
  min-height: 70vh;
}  
.footer{
  font-size: 2.1vw;
}

.btn-sec1{
  font-size: 3vw;
}

.btn-sec2{
  font-size: 3vw;
}

.btn-sec3{
  font-size: 3vw;
}

.navbar-todo a{
  color:#00c3ff;
}

.form-input:placeholder-shown{
  text-overflow: ellipsis;
}

.nav a{
  color:rgb(255, 255, 255)
}

.section-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}

.sec1-text{
  width: 100%;
  text-align: center;
  font-size: 4.2vw;
}

.section-2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}

.sec2-text{
  width: 100%;
  text-align: center;
  font-size: 4.2vw;
}

.section-3{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}

.sec3-text{
  width: 100%;
  text-align: center;
  font-size: 4.2vw;
}
.seperator{
  height:10vh;
}
} 

@media screen and (max-width:320px){
  .home-heading h1{
    font-size:calc(28px + 1.2vw);
  }
  .home-heading h2{
    font-size:calc(20px + 0.8vw);
  }
}

@media screen and (max-width:1440px){
  .about-text p{
    font-size:calc(10px + 0.8vw);
  }
}

@media screen and  (max-width:400px) {
  .filters{
    display: flex;
    flex-wrap: wrap;
  }
  
  .toggle-btn:first-of-type{
    border-radius: 5px;
    margin-bottom: 1px;
    width:20%;
  }
  
  .toggle-btn:nth-child(2){
    word-wrap: break-word;
    border-radius: 5px;
    width: 40%;
  }
  .toggle-btn:last-of-type{
    margin-top:1px;
    border-radius: 5px;
    word-wrap: break-word;
    width:60%;
  }
}

@media screen and  (max-width:270px) {
  .filters{
   flex-direction: column;
  }
  .toggle-btn:first-of-type{
    width: 60%;
  }
  
  .toggle-btn:nth-child(2){
    width: 60%;
  }
  .toggle-btn:last-of-type{
    width: 60%;
  }
}